<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal pb-2" />

    <form @submit.prevent="submit">
      <section :style="{
      'background-image': 'url(' + banner_url + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100%',
      'background-position': 'center',
    }" class="
  free_listing-banner
  d-flex
  align-items-center
  justify-content-center
  position-relative
  ">
        <div class="container">
          <div class="banner_content-wrapper">
            <div class="banner_image__picker text-center pointer">
              <input type="file" name="file-input" id="file-input" class="
  file-input__input
  position-absolute
  overflow-hidden
  invisible
  " @change="onFilebanner" ref="banner" />
              <label class="
  file-input__label
  d-inline-flex
  align-items-center
  text-white
  pointer
  rounded
  grey-bg
  py-2
  px-3
  f-18
  text-dark
  " for="file-input">
                <template v-if="!banner_url">
                  <img src="@/assets/images/AddUser.svg" alt="Add User">
                  <span class="f-16 fw-400 text-center">Upload <br> Iogo</span>
                </template>

              </label>
            </div>
          </div>
        </div>
      </section>
      <!-- <img v-if="banner_url" :src="banner_url" /> -->

      <section class="listing_page-body py-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-xl-8">
              <div class="card border">
                <div class="card-body">
                  <div class="row">
                    <div class="d-flex justify-content-between"> 
                    <div class="over-view__wrapper">
                      <h2 class="gray-text f-18">Restaurant Info</h2>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <div class="form-check">
                        <input 
                          class="form-check-input fs-19" 
                          type="checkbox" 
                          v-model="form.is_member" 
                          @change="updateVisitStatus('membership_status')" 
                          id="flexCheckDefault2" 
                        />
                      </div>
                      <label class="form-check-label fs-18 dark-text" for="flexCheckDefault2">
                        OFL Partner
                      </label>
                    </div>
                  </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Firstname-input">
                          Restaurant Name
                        </label>
                        <input type="text" class="form-control rounded-pill" id="formrow-Firstname-input"
                          v-model.trim="$v.form.title.$model" placeholder="Enter Restaurant Name" />
                        <div class="error" v-if="$v.form.title.$error">
                          Title field is required
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-email-input">Email</label>
                        <input type="email" class="form-control rounded-pill" id="formrow-email-input"
                          v-model="form.email" placeholder="Enter Email Address" />
                          <div class="error" v-if="$v.form.email.$error">
                          Email is required
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-OpenDate-input">Restaurant Open Date</label>
                        <input type="date" class="form-control rounded-pill" id="formrow-OpenDate-input"
                          placeholder="Restaurant Open Date" v-model="form.resturant_establish" />
                      </div>
                    </div> -->
                    <template v-if="form.is_member">
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-ofl-input">OFL Exclusive Name</label>
                        <input type="text" class="form-control rounded-pill" id="formrow-ofl-input"
                          placeholder="OFL Exclusive Name" v-model ="form.ofl_exclusive"/>
                      </div>
                    </div>
                  
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label" for="Exclusive">OFL Exclusive Description</label>
                        <textarea type="text" class="form-control form-control rounded-pill" id="Exclusive"
                          placeholder="OFL Exclusive Description" v-model="form.exclusive_description" />
                      </div>
                    </div> -->
                  </template>
                  <div :class="form.is_member ? 'col-md-6' : 'col-md-6'">
                    <div class="mb-3">
                        <label class="form-label " for="formrow-Contact-input">
                          Contact No.
                        </label>
                        <input type="tel" class="form-control rounded-pill" id="formrow-Contact-input"
                          placeholder="(907) 555-0101" v-model="form.mobile" />
                      </div>
                  </div>
                    <div class="col-md-6">
                      

                      <div class="mb-3" v-for="(field, index) in formFields" :key="'rating-' + index">
                        <label class="form-label " for="category">Category</label>


                        <multiselect v-model="field.temCategory" :multiple="false" :options="categoryOptions"
                          :searchable="true"
                           @search-change="searchCategory"
                          @fetchMore="loadMoreCategories" :class="className">
                        </multiselect>


                      </div>
                      <a @click="addField" class="btn bg-transparent orange-text f-18 add-new-cat mb-3">
                        Add another category +
                      </a>

                    </div>
                    <div class ="col-md-6">
                      <div class="mb-3 position-relative" v-for="(field, index) in formFields" :key="index">
                        <label class="form-label" for="formrow-ranking-select">OFL Rating</label>
                        <select class="form-select form-control rounded-pill" v-model="field.tempCategoryrating"
                          aria-label="select example">
                          <option disabled value="">Select Rating (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                        <div :key="'delete-' + index" class="col-md-12 position-absolute detle-icon">
                          <i v-if="formFields.length > 1 && index != 0" class="fa fa-trash text-muted del-icon mt-2"
                            @click="deleteField(index)" style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Location Category</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select"
                          aria-label="select example" v-model="locationCategoryNew.id">
                          <option disabled value="" selected>Select location category</option>
                          <option v-for="(locationtype, index) in locationCategories" :key="index" :value="locationtype.id">{{locationtype.title }}</option>
                        </select>
                      </div>
                    </div>
                    <div class ="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Location Category Rating</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select"
                          aria-label="select example" v-model="locationCategoryNew.rating">
                          <option disabled :value=null selected>Select Rating (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label " for="Website">Website</label>
                        <input type="text" class="form-control rounded-pill" id="Website" placeholder="www.xyz.com"
                          v-model.trim="$v.form.website.$model" />
                        <div class="error" v-if="$v.form.website.$error">
                          Website field is required
                        </div>
                      </div>
                    </div>
                    <div class ="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Overall Ranking</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select"
                          aria-label="select example" v-model="form.ranking">
                          <option disabled :value=null selected>Select Ranking (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">


                      <!-- Ranking Select Field -->




                      <!-- Rating Select Field -->
                      

                      <div class="mb-3">
                        <label class="form-label" for="overview">Short Restaurant Description</label>
                        <textarea type="text" class="form-control form-control rounded-pill" id="overview"
                          placeholder="Short Restaurant Description" v-model="form.overview" />
                      </div>
                     
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Primary Location Type</label>
                        <select @change="handleSingle" class="form-select form-control rounded-pill"
                          id="formrow-rating-select" aria-label="select example" v-model="form.single">
                          <option disabled value="" selected>Select other locations</option>
                          <option v-for="(locationtype, index) in locationTypes" :key="index" :value="locationtype.id">{{
      locationtype.title }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12">

                      <div class="mb-3 mt-3">
                        <template>
                          <div class="">
                            <label class="form-label" for="formrow-rating-select">Location Type</label>

                            <v-select v-model="selectedLocationTypes" :options="locationmultiTypes" label="title"
                              :getOptionLabel="getOptionLabel" @input="selectLocationType" class="custom-dropdown"
                              multiple :close-on-select="false">
                              <!-- This is how to define the option slot correctly -->
                              <template v-slot:option="option">
                                <div class="d-flex align-items-center gap-2">
                                  <img :src="option.icon" alt="Icon" width="20" class="option-icon">
                                  <span>{{ option.title }}</span>
                                </div>
                              </template>
                            </v-select>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- <input
  @change="uploadLogo"
  accept="image/*"
  type="file"
  id="logo"
  class="file-input__input position-absolute overflow-hidden invisible"
  />
  <label
  class="file-input__label d-inline-flex align-items-center text-white pointer rounded grey-bg py-2 px-3 f-18 text-dark"
  for="logo"
  >
  <i class="uil-upload f-20 pe-2"></i>
  <span class="f-20 fw-medium">Upload Image</span>
  </label>-->
                  <!--------------------- Select user---------->

                  <!-- <div class="row">
  <div class="over-view__wrapper col-12">
  <h2 class="gray-text f-18 fw-600">Select User</h2>
  </div>
  <div class="col-md-12 col-12">
  <div class="mb-3">
  <label for="category" class="form-label">User</label>
  <select v-model="form.user_id" id="category" aria-label="select example"
  class="form-select form-control rounded-pill">
  <option disabled :value="null">
  Please Select an User
  </option>
  <option v-for="(user, index) in users" :key="index" :value="user.id">
  {{ user.full_name }}
  </option>
  </select>
  </div>
  </div>
  </div> -->

                  <!--------------------- Select user---------->
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18 fw-600">Select Price Range</h2>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="mb-3">
                        <!-- <label for="category" class="form-label">Price Range</label> -->
                        <select v-model="form.price_range" id="category" aria-label="select example"
                          class="form-select form-control rounded-pill">
                          <option disabled :value="null">
                            Please Select Price Range
                          </option>
                          <option value="$">
                            $
                          </option>
                          <option value="$$">
                            $$
                          </option>
                          <option value="$$$">
                            $$$
                          </option>
                          <option value="$$$$">
                            $$$$
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- ------------ Select price range ------------ -->
                  <div class="row mb-2">
                    <div class="over-view__wrapper col-12">
                      <h2 class="gray-text f-18 fw-600">Social Profiles</h2>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="facebook">Facebook</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Facebook" aria-label="Facebook"
                          aria-describedby="addon-wrapping" class="form-control rounded-pill"
                          v-model="form.social.facebook" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="instagram">Instagram</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Instagram" aria-label="Instagram"
                          aria-describedby="addon-wrapping" v-model="form.social.instagram"
                          class="form-control rounded-pill" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="twitter">Twitter</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Twitter" aria-label="Twitter" aria-describedby="addon-wrapping"
                          class="form-control rounded-pill" v-model="form.social.twitter" />
                      </div>
                    </div>
                    <!-- <div class="col-md-4">
                      <label class="form-label mb-0" for="google">Yelp</label>
                      <div class="input-group flex-nowrap my-2">
                        <input type="text" placeholder="Yelp" aria-label="Google" aria-describedby="addon-wrapping"
                          class="form-control rounded-pill" v-model="form.social.google" />
                      </div>
                    </div> -->
                  </div>
                  <!--------------------- free listing Page Gallery---------->
                  <div class="features_gallery--wrapper ">
                    <h2 class="gray-text f-18 fw-600">Photo Gallery</h2>
                    <div class="images_wrapper-features-gallery row">
                      <div class="col-md-6">
                        <div class="image_wrapper">
                          <label for="media" class="f-14 fw-medium text-dark">Listing Photos*(JPEG,JPG,PNG)</label>
                          <UploadImages @changed="handleImages" />
                        </div>
                        <!--------------------- free listing Page Video---------->
                        <div class="video wrapper">
                          <h2 class="gray-text f-18 fw-600 mt-4">Video Gallery</h2>
                          <label for="media" class="f-14 fw-medium text-dark">Listing Videos*(Max Size 50MB)</label>
                          <span class="text-danger" v-if="videoError">Video Size Too Much(Max 50MB)</span>
                          <div class="gallery_video-wrapper mt-3 position-relative">
                            <div class="
  video-file-input
  position-relative
  text-center
  rounded
  pointer
  ">
                              <input @change="uploadVideo" accept="video/*" type="file" name="file-input"
                                id="file-input-video" class="file-input__input" ref="videoInput" />
                              <label for="file-input-video" class="
  file-input__label
  d-inline-flex
  align-items-center
  text-white
  pointer
  
  grey-bg
  py-2
  px-3
  f-18
  text-dark
  ">
                                <img src="@/assets/images/AddUser.svg" alt="Add User">
                                <span v-if="video_url.length >= 1" class="f-20 fw-medium">Upload More</span>
                                <span v-else class="f-20 fw-medium">Upload videos</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="features_gallery--wrapper dd">
                      <div :class="video_url.length >= 1 ? 'grey-bg my-4 p-md-4' : 'transparent'" class="
                          videos-show_main
                          d-flex
                          col-md-6
                          flex-wrap
                          rounded
                          ">
                        <div v-for="(single, index) in video_url" :key="index" class="
                            videos_wrapper-inner
                            col-md-6 col-12
                            position-relative
                            ">
                          <video :src="single" width="100%" height="100%" controls
                            class="video_styles rounded p-1"></video>
                          <span @click="removeVideo(index)" class="video_del-icon pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="16px" height="16px">
                              <path fill="#f78f8f"
                                d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z" />
                              <path fill="#c74343"
                                d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z" />
                              <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-134.999 20 20)" />
                              <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-45.001 20 20)" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Faq---------->
                  <div class="listing__faq py-4">
                    <div class="faq_inner-listing">
                      <h2 class="gray-text f-18 fw-600">
                        Frequently Asked Questions
                      </h2>
                      <div style="padding-bottom: 10px" class="faq_box-wrapper">
                        <div style="margin-bottom: 10px" v-for="(item, index) in faq" :key="index" class="
                            faq_form-box-inner
                            border-secondary
                            rounded
                            p-3
                            ">
                          <!-- <form class="listing_faq-form"> -->
                          <input type="text" name="listing_faq-question" id="listing_faq-question"
                            placeholder="Question" class="w-100 py-2 form-control rounded-pill "
                            v-model="item.question" />
                          <div class="input-group">
                            <textarea class="form-control mt-3 w-100 rounded-pill" aria-label="With textarea" rows="4"
                              cols="50" id="listing_overview" placeholder="Answer" v-model="item.answer"></textarea>
                          </div>
                          <!-- <div class="list_add-faq pt-4">
  <div
  class="
  list_add-faq-inner
  p-3
  rounded
  grey-bg
  pointer
  "
  >
  <h3 class="text-dark f-16 fw-semibold m-0">
  <i
  class="
  fa fa-plus fa-solid fa-sharp
  text-dark
  pe-2
  f-20
  "
  ></i
  >Add Another
  </h3>
  </div>
  </div> -->
                          <div class="py-2 float-end me-3 "> 
                            <i v-show="index != 0" @click="removeFaqBox(index)" class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                          </div>
                       
                        </div>
                      </div>
                      <a @click="addMore()" class="btn bg-tranparent orange-text f-18 p-0">
                        Add +
                      </a>
                    </div>
                  </div>
                  <!-- Dish Section -->
                  <div class="dish_inner-listing pb-4">
                    <div class="col-md-12 mb-3">
                      <h2 class="gray-text f-18 fw-600">Add Menu Link</h2>
                      <input type="text" name="menu_link" id="menu_link" v-model="manu_link" placeholder="Menu Link"
                        class="w-100 py-2 form-control rounded-0 rounded-pill" />
                    </div>
                    <!-- 

                    <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div style="margin-bottom: 10px" v-for="(dish, index) in dishes" :key="index"
                        class="dish_form-box-inner border-secondary rounded pt-3">
                     
                        <div class="form-group mb-3">
                          <label for="dish_image" class="form-label"></label>
                          <input type="file" class="form-control rounded-pill" id="dish_image"
                            @change="onImageChange($event, index)" />

                          <img v-if="dish.previewImage" :src="dish.previewImage" alt="Dish Image Preview"
                            class="img-fluid mt-2" style="max-height: 150px;" />
                        </div>

                      
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Dish Name"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="dish.name" />
                          </div>

                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Dish Price"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="dish.price" />
                          </div>
                       
                        </div>

                        <div class="py-2 float-end me-3  ">
                          <i v-show="index != 0"  @click="removeDishBox(index)" class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <a @click="addMoreDish()" class="btn bg-tranparent orange-text f-18 p-0">Add +</a> -->
                  </div>
                  <!-- hide section -->

                  <div class="dish_inner-listing pb-4" v-if="form.is_member">
                    <h2 class="gray-text f-18 fw-600">Secret Menu </h2>

                    <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div style="margin-bottom: 10px" v-for="(secret, index) in secretMenu" :key="index"
                        class="dish_form-box-inner border-secondary rounded pt-3">
                        <!-- Dish Image Upload -->
                        <div class="form-group mb-3">
                          <label for="secret_image" class="form-label"></label>
                          <input type="file" class="form-control rounded-pill" id="secret_image"
                            @change="onSeceretImageChange($event, index)" />

                          <img v-if="secret.previewImage" :src="secret.previewImage" alt=" Image Preview"
                            class="img-fluid mt-2" style="max-height: 150px;" />
                        </div>

                        <!-- Dish Name -->
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Name"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="secret.name" />
                          </div>
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Description"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="secret.price" />
                          </div>
                        </div>
                        <div class="py-2 float-end me-3  " v-if ="index > 0">
                          <i v-show="index != 0"  @click="removeSecretMenu(index)" class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        
                      </div>
                        </div>
                        
                        </div>
                     
                      <div>
                     <a @click="addSecret()" class="btn bg-tranparent orange-text f-18 p-0">Add +</a>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-4">
              <div class="card border shadow-none">
                <div class="card-body">
                  <!-- <form class="side-bar_listing-form"> -->
                  <!--------------------- free listing Sidemenu Location---------->
                  <div class="listings-location_main rounded">
                    <div class="location_inner-wrappper rounded">
                      <div class="location_info-wrapper">
                        <div class="row">
                          <div class="col-md-12">
                            <AddressFields :modelValue="address" @update:modelValue="address = $event" />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Sidemenu HOurs---------->
                  <div class="listings-hours_main my-3">
                    <div class="location_listing-hours-wrapper rounded">
                      <div class="hours_header d-flex align-items-center justify-content-between flex-wrap">

                        <label class="features-label form-check-label gray-text f-18 pointer pe-4"
                          for="availibilityHours">
                          Restaurant Hours</label>

                        <div class="hour_toggle">
                          <input @click="hoursStatus" class="form-check-input" v-model="form.availability_hours"
                            type="checkbox" id="availibilityHours" />
                          <label class="features-label form-check-label text-secondary f-12 pointer ps-2"
                            for="availibilityHours">Hide Hours</label>
                        </div>
                      </div>

                      <div class="
                          hours-header
                          row
                          justify-content-between
                          align-items-center
                          py-2
                          ">
                        <div v-if="form.availability_hours" class="col-4">
                          <!-- <h2 class="text-dark f-16 fw-bold m-0">Hours</h2> -->
                        </div>
                      </div>
                      <div v-if="form.availability_hours" class="hours-listing-body">
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                            col-12
                            text-start
                            py-1 py-lg-0
                            col-xl-12
                            col-md-4
                            f-16
                            text-dark
                            ">
                            <span>Monday</span>
                          </div>

                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="monday-start-time" type="time"
                              name="start-time" v-model="time.mondayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 w-100" id="appt-end-time" type="time"
                              v-model="time.mondayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-xl-12
                              col-md-4
                              f-16
                              text-dark
                              ">
                            <span>Tuesday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              name="start-time" v-model="time.tuesdayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-end-time" type="time"
                              name="end-time" v-model="time.tuesdayTo" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                            col-12
                            text-start
                            py-1 py-lg-0
                            col-xl-12
                            col-md-4
                            f-16
                            text-dark
                            ">
                            <span>Wednesday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              name="start-time" v-model="time.wednesdayFrom" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 w-100" id="appt-end-time" type="time"
                              v-model="time.wednesdayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-xl-12
                              col-md-4
                              f-16
                              text-dark
                              ">
                            <span>Thursday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              v-model="time.thursdayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-end-time" type="time"
                              name="end-time" v-model="time.thursdayTo" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                            col-12
                            text-start
                            py-1 py-lg-0
                            col-xl-12
                            col-md-4
                            f-16
                            text-dark
                            ">
                            <span>Friday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              v-model="time.fridayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-end-time" type="time"
                              v-model="time.fridayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                          col-12
                          text-start
                          py-1 py-lg-0
                          col-xl-12
                          col-md-4
                          f-16
                          text-dark
                          ">
                            <span>Saturday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              v-model="time.saturdayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-end-time" type="time"
                              v-model="time.saturdayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                        <div class="hours-body-inner row align-items-center my-2">
                          <div class="
                                col-12
                                text-start
                                py-1 py-lg-0
                                col-xl-12
                                col-md-4
                                f-16
                                text-dark
                                ">
                            <span>Sunday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-start-time" type="time"
                              v-model="time.sundayFrom" name="start-time" value="00:00" />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input class="border rounded-pill pointer p-1 time-field w-100" id="appt-end-time" type="time"
                              v-model="time.sundayTo" name="end-time" value="00:00" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class ="d-flex justify-content-center"> 
                    <div class="cta_submit button text-end border-orange fit-content rounded-pill p1">
                    <SubmitButton :processing="processing" type="submit" class="
                      btn btn-primary
                      waves-effect waves-light
                      py-2
                      px-5
                      w-auto
                      " text="Submit" />
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import UploadImages from "vue-upload-drop-images";
import AddressFields from "../AddressFields.vue";
import { required } from "vuelidate/lib/validators";
import { VueCsvImport } from "vue-csv-import";
import Multiselect from "../../../../components/general/multiSelect.vue";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
  page: {
    title: "Add Restaurant",
    meta: [
      {
        name: "approved",
        content: appConfig.description,
      },
    ],
  },
  components: {
    UploadImages,
    Layout,
    PageHeader,
    AddressFields,
    Multiselect,
    vSelect,
  },
  data() {
    return {
      categoryNextPageUrl: null,
      videoError: false,
      locationCategories: [],
      categoryOptions: [],
      categorySelected: [],
      selected: [],
      options: [],
      selectedLocationTypes: [],
      locationmultiTypes: [],
      title: "Add Resturants",
      className: "",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Add Restaurants",
          active: true,
        },
      ],
      processing: false,
      faq: [
        {
          question: null,
          answer: null,
        },
      ],
      manu_link: null,
      dishes: [{
        image: null,
        name: '',
        price: '',
        type: '',
        previewImage: null,
      }
      ],
      secretMenu: [{
        image: null,
        name: '',
        price: '',
        previewImage: null,

      }], 
      dish_media: [],
      secret_media:[],
      time: {
        mondayFrom: null,
        mondayTo: null,
        tuesdayFrom: null,
        tuesdayTo: null,
        wednesdayFrom: null,
        wednesdayTo: null,
        thursdayFrom: null,
        thursdayTo: null,
        fridayFrom: null,
        fridayTo: null,
        saturdayFrom: null,
        saturdayTo: null,
        sundayFrom: null,
        sundayTo: null,
      },
      video_url: [],
      banner_url: null,
      banner: "",
      logo: "",
      video: [],
      categories: null,
      subCategories: null,
      users: null,
      address: {
        longitude: null,
        latitude: null,
        city: "",
        state: "",
        country: "",
        address: "",
        zip_code: "",
        place_id: null,
      },
      allFeature: [],
      feature: [],
      media: [],
      locationTypes: [],
      locationCategoryNew:{
        id:'',
        rating:null,
      },
      form: {
        ofl_exclusive: null,
        resturant_establish: null,
        exclusive_description:null,
        single: '',
        availability_hours: true,
        title: null,
        city: null,
        state: null,
        country: null,
        address: null,
        zip_code: null,
        price_range: null,
        email: null,
        mobile: null,
        rating: null,
        ranking: null,
        is_member: null,
        website: null,
        longitude: null,
        latitude: null,
        category_id: [],
        sub_category_id: [],
        selectedLocationTypeIds: [],
        user_id: null,
        overview: null,
        place_id: null,
        social: {
          facebook: null,
          instagram: null,
          twitter: null,
          google: null,
        },
        time: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
      },
      formFields: [{ temCategory: "", tempCategoryrating: "" }],

    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      website: {
        required,
      },
      
    },
  },
  watch: {
    parseCsv(newValue, oldValue) {
      console.log(newValue);
      return (this.newRecord = newValue.slice(1));
    },
    locationTypes(newValue) {
      return newValue;
    }
  },
  mounted() {
    this.locationCategory();
    this.allCategory();
    this.allFeatures();
    this.allUser();
    this.allLocationType();
  },
  methods: {
    debounce(func, delay) {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    },
    searchCategory: function(query) {
      this.$axios
        .get("search-category", { params: { query } })
        .then((response) => {
          this.categoryOptions = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    locationCategory() {
      this.$axios
        .get("location-category", { params: { page_size: 100 } })
        .then((response) => {
          // console.log()

          this.locationCategories = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    loadMoreCategories() {
      if (this.categoryNextPageUrl) {
        this.allCategory(this.categoryNextPageUrl)
      } else {
        console.log("No more categories to load.");
      }
    },
    addField() {
      return this.formFields.push({ temCategory: "", tempCategoryrating: "" });
    },
    deleteField(index) {
      if (this.formFields.length > 1) {
        this.formFields.splice(index, 1);
      }
    },



    onFilebanner(e) {
      const file = e.target.files[0];
      this.banner = this.$refs.banner.files[0];
      this.banner_url = URL.createObjectURL(file);
    },
    selectMedia(e) {
      const file = e.target.files[0];
      console.log(file);
    },
    allFeatures() {
      this.$axios
        .get("feature")
        .then((response) => {
          this.allFeature = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit() {
      this.selected.forEach((element) => {
        this.form.sub_category_id.push(element.id);
      });
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        if (this.form.availability_hours == true) {
          this.form.time.monday = this.time.mondayFrom + "-" + this.time.mondayTo;
          this.form.time.tuesday =
            this.time.tuesdayFrom + "-" + this.time.tuesdayTo;
          this.form.time.wednesday =
            this.time.wednesdayFrom + "-" + this.time.wednesdayTo;
          this.form.time.thursday =
            this.time.thursdayFrom + "-" + this.time.thursdayTo;
          this.form.time.friday = this.time.fridayFrom + "-" + this.time.fridayTo;
          this.form.time.saturday =
            this.time.saturdayFrom + "-" + this.time.saturdayTo;
          this.form.time.sunday = this.time.sundayFrom + "-" + this.time.sundayTo;
        }
        let formData = new FormData();
        this.processing = true;
        this.form.zipcode = this.address.zipcode;
        this.form.city = this.address.city;
        this.form.state = this.address.state;
        this.form.country = this.address.country;
        this.form.longitude = this.address.longitude;
        this.form.latitude = this.address.latitude;
        this.form.place_id = this.address.place_id;
        // this.form.media = this.form.media.map((media) => media.name);

        formData.append("banner", this.banner);
        this.form.address = this.address.address;
        formData.append("address", JSON.stringify(this.address));
        formData.append("form", JSON.stringify(this.form));
        formData.append("faq", JSON.stringify(this.faq));
        formData.append("dishes", JSON.stringify(this.dishes));
        formData.append("secret_menu", JSON.stringify(this.secretMenu));
        // let dishes_images = [];
        for (let i = 0; i < this.dishes.length; i++) {
          if (this.dishes[i].image) {
            formData.append(`dish_media[${i}]`, this.dishes[i].image);
          }
        }
        for (let i = 0; i < this.secretMenu?.length; i++) {
          if (this.secretMenu[i].image) {
            formData.append(`secret_media[${i}]`, this.secretMenu[i].image);
          }
        }
        // formData.append("video", this.video);
        for (var v = 0; v < this.video.length; v++) {
          let file = this.video[v];
          formData.append("video[" + v + "]", file);
        }
        for (var i = 0; i < this.media.length; i++) {
          let file = this.media[i];
          formData.append("media[" + i + "]", file);
        }
        formData.append("feature", JSON.stringify(this.feature));
        formData.append('manu_link', this.manu_link);
        formData.append('location_category',JSON.stringify(this.locationCategoryNew));

        this.formFields.forEach((field, index) => {
          const rating =  field.tempCategoryrating || 0;
          formData.append(`categories[${index}]`, field.temCategory.id);
          formData.append(`rating[${index}]`, rating);
        });
        this.$axios
          .post("add-business-listing", formData)
          .then((response) => {
            // console.log(response);
            this.$router.push({ path: "/business/listing" });
            this.triggerSwal(response.data, "success");
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          })
          .finally(()=>{
            this.processing = false;
          })
      }
    },
    allCategory(url) {
      let endPoint = url ? url : "categories";
      this.$axios
        .get(endPoint)
        .then((response) => {
          this.categories = response.data.data.data;
          let allCategories = response.data.data.data;
          this.categoryOptions = url ? [...this.categoryOptions, ...allCategories] : allCategories;
          this.categoryNextPageUrl = response.data.data.next_page_url;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    changeCategory({ event, index }) {
      // this.form.category_id.push(val.id);
      const selectedCategoryId = event.target.value;
      this.formFields[index].category = selectedCategoryId;

      // console.log(val);
      // this.categorySelected.forEach(element => {
      // this.options=[];
      // this.$axios.get("subCategories/" + val.id).then(response => {
      // response.data.data.forEach(element => {
      // this.options.push(element);
      // })
      // })
      // console.log(this.options);
      // });
    },
    allUser() {
      this.$axios
        .get("all-users-for-list")
        .then((response) => {
          this.users = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadLogo(e) {
      this.logo = e.target.files[0];
    },
    uploadVideo(e) {
      let video = e.target.files[0];
      if (video.size >= 5000000) {
        return this.videoError = true
      }
      this.videoError = false;
      this.video.push(video);
      let video_url = URL.createObjectURL(video);
      this.video_url.push(video_url);
      if (this.video_url.length <= 0) {
        e.target.value = '';
      }
      console.log(this.video_url);
    },
    handleImages(files) {
      console.log(files);
      this.media = [];
      for (let i = 0; i < files.length; i++) {
        this.media.push(files[i]);
      }
      console.log(this.media);
    },
    removeVideo(index) {
      this.video_url.splice(index, 1);
      this.$refs.videoInput.value = ''

    },
    addMore() {
      this.faq.push({
        question: null,
        answer: null,
      });
      // let obj = {};
      // obj.question = this.question;
      // obj.answer = this.answer;
      // this.faq.push(obj);
      // this.question = null;
      // this.answer = null;
      // obj = {};
    },
    removeFaqBox(index) {
      this.faq.splice(index, 1);
    },
    addMoreDish() {
      this.dishes.push({ image: null, name: '', type: '' });
    },
    removeDishBox(index) {
      this.dishes.splice(index, 1);
    },
    addSecret(){
      this.secretMenu.push({ image: null, name: '', type: '' });
    },
    removeSecretMenu(index) {
      this.secretMenu.splice(index, 1);
    },
    onImageChange(event, index) {
        const file = event.target.files[0];
         if (file) {
          this.$set(this.dishes, index, {
            ...this.dishes[index], 
            image: file, 
           previewImage: URL.createObjectURL(file), 
     });

      let dishImage = document.getElementById(`dish-${index}`);
      dishImage.classList.add('d-block');
    }
  },
    // onImageChange(event, index) {
    //   const file = event.target.files[0];
    //   this.dishes[index].image = file;
    //   if (file) {
    //     this.dishes[index].previewImage = URL.createObjectURL(file);
    //   }
    // },
    onSeceretImageChange(event, index) {
      const file = event.target.files[0];
  if (file) {
    const previewImage = URL.createObjectURL(file);
    this.$set(this.secretMenu, index, {
      ...this.secretMenu[index],
      image: file,
      previewImage: previewImage,
    });

    this.$nextTick(() => {
      let secretImage = document.getElementById(`secret-${index}`);
      if (secretImage) {
        secretImage.classList.add("d-block");
      }
    });
  }
    },

    updateVisitStatus(statusType) {
  const status = this.form.is_member ? '1' : '0';
  console.log(`${statusType} changed to ${status}`);
  // Perform additional functionality using `status`
},
    hoursStatus() {
      this.form.availability_hours = !this.form.availability_hours
      // console.log(this.form.availability_hours);
    },
    allLocationType() {
      this.$axios
        .get("location-type", { params: { page_size: 100 } })
        .then((response) => {
          // console.log()
          this.locationTypes = response.data.data.data;
          this.locationmultiTypes = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getOptionLabel(option) {
      return option.title;
    },
    handleSingle(event) {
      this.locationmultiTypes = [];
      if (this.form.selectedLocationTypeIds.length) {
        this.form.setSelectedLocation = [];
      }
      if (!this.form.selectedLocationTypeIds.includes(this.form.single)) {
        this.form.selectedLocationTypeIds.push(this.form.single);
      }
      this.locationmultiTypes = this.locationTypes.filter(item => item.id !== this.form.single);
    },
    selectLocationType(selectedOptions, id) {
      selectedOptions.map(option => {
        if (!this.form.selectedLocationTypeIds.includes(option.id)) {
          this.form.selectedLocationTypeIds.push(option.id)
        }
      });
      console.log(this.form.selectedLocationTypeIds);
    }
  },
  created() {
    this.searchCategory = this.debounce(this.searchCategory, 500);
  }
};

</script>
<style>
/* .btn-danger.del-btn {
  position: absolute;
  right: 0;
  top: 20px;
 } */
</style>